.legend__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px #eeeeee;
}

.legend__avatar {
  background-color: "#3e4e";
  width: 10px;
  height: 10px;
}

.legend__avatar--category {
  color: transparent;
  margin-left: 20px;
  margin-bottom: 8px;
  margin-top: 8px;
  flex: 0 1 auto;
}
