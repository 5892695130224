/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

/* ----- v CAN BE DELETED v ----- */
body {
  background-color: #78909c;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #ffffff;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer > a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.btn {
  padding: 1rem 1.5rem;
  background: white;
  text-align: center;
  display: inline-block;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  will-change: transform;
  width: 200px;
  height: 40px;
  border: none;
}

.btn-tutorial {
  font: 1rem "Barlow Semi Condensed", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
  background: #3f51b5;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  border-radius: 0.3rem;
  z-index: 1;
  /* box-shadow: 0 0 10px 5px #3f51b5; */
}

.btn-tutorial {
  color: #fff;
  transition: all 0.5s;
  position: relative;
}

.btn-tutorial:disabled {
  box-shadow: none;
  background-color: #cccccc;
}

.btn-tutorial:enabled {
  animation: shadow-pulse 1s;
  animation-iteration-count: 15;
}

.btn-tutorial::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s;
}

.btn-tutorial:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}

.btn-tutorial::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 2px solid #1a237e;
  transform: scale(1.2, 1.2);
}

.btn-tutorial:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

/* 
.btn-mat {
  color: black;
  border: 0px transparent;
  border-radius: 0.3rem;
  transition: 0.3s ease-in-out;
  transition-delay: 0.35s;
  overflow: hidden;
}

.btn-mat:before {
  content: "";
  display: block;
  background: blue;
  position: absolute;
  width: 200%;
  height: 500%;
  border-radius: 100%;
  transition: 0.36s cubic-bezier(0.4, 0, 1, 1);
}

.btn-mat:hover .psuedo-text {
  color: white;
}
.btn-mat:hover {
  color: transparent;
} */
