#central-flow {
  fill: url(#flow);
}

.b_container_0 {
  width: 100%;
  height: 100%;
}

.stop-left_0 {
  stop-color: #65a0ec; /* Indigo */
}

.stop-right_0 {
  stop-color: #3f51b5; /* Teal */
}

.filled_0 {
  fill: url(#mainGradient-0);
}

.stop-left_1 {
  stop-color: #e76de5; /* Indigo */
}

.stop-right_1 {
  stop-color: #4b4cdd; /* Teal */
}

.filled_1 {
  fill: url(#mainGradient-1);
}

.stop-left_2 {
  stop-color: #dd4b92; /* Indigo */
}

.stop-right_2 {
  stop-color: #e79d6d; /* Teal */
}

.filled_2 {
  fill: url(#mainGradient-2);
}

.stop-left_3 {
  stop-color: #7857ea; /* Indigo */
}

.stop-right_3 {
  stop-color: #ba68c8; /* Teal */
}

.filled_3 {
  fill: url(#mainGradient-3);
}

.stop-left_4 {
  stop-color: #e76de5; /* Indigo */
}

.stop-right_4 {
  stop-color: #000000; /* Teal */
}

.filled_4 {
  fill: url(#mainGradient-4);
}

.stop-left_5 {
  stop-color: #dd4b92; /* Indigo */
}

.stop-right_5 {
  stop-color: #e79d6d; /* Teal */
}

.filled_5 {
  fill: url(#mainGradient-5);
}
